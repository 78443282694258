import { Fragment, useRef, useState } from "react";
import {
	correctDataUTC,
	getStatusPedidosTransferencia,
	getTransferencias,
	locationDev,
	updateTable,
	valorMoeda,
} from "../../../services/Servicos";
import { QRCodeSVG } from "qrcode.react";

import "./BodyRow.scss";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import ModalError from "../../modal/ModalError";
import ModalListarProdutosTransferencias from "../../modal/ModalListarProdutosTransferencias";
import ModalAddPedidosTransferencia from "../../modal/ModalAddPedidosTransferencia";
import Loading from "../../loading/Loading";
import { updateTransferencias } from "../../../redux/slicers/user";
import { getColorOfFilial, getErrorMessage, getFilialName } from "../../../services/helpers";
import ModalErrorPretty from "../../modal/ModalErrorPretty";

export default function BodyRowPedidosTransferencia({
	headList,
	bodyList,
	index,
	tableLength,
	setTransferenciaSelecionada = () => {},
	setArrayFilters = () => {},
}) {
	const refRow = useRef(null);
	const refEditar = useRef(null);
	const user = useSelector((state) => state.userSlice);
	const filiaisList = user.filiaisList.data;

	const [produtosModal, setProdutosModal] = useState({
		flag: false,
		numero_pedido: "",
	});

	const onClickPlusRow = (e) => {
		e.preventDefault();

		setProdutosModal({
			flag: true,
			transferencia: bodyList.transferencia,
			pedido: bodyList,
		});
	};

	const onClickTableRow = (e) => {
		e.preventDefault();
		refRow.current.classList = "bg-selected";
		let refRowNumber = refRow.current.children[0].innerText;

		let i = refRowNumber;
		let actualSibling = refRow.current.nextSibling;

		while (i <= tableLength) {
			if (actualSibling === null) {
				break;
			}
			actualSibling.classList = "";

			actualSibling = actualSibling.nextSibling;
			i++;
		}

		i = refRow.current.children[0].innerText;
		actualSibling = refRow.current.previousSibling;
		while (0 < i) {
			if (actualSibling === null) {
				break;
			}
			actualSibling.classList = "";
			actualSibling = actualSibling.previousSibling;
			i--;
		}
		setTransferenciaSelecionada(bodyList);
	};

	const [verPDF, setVerPDF] = useState({
		show: false,
		PDF: "",
		title: "",
	});

	const [fotoProdutoEntregue, setFotoProdutoEntregue] = useState({
		flag: false,
		imgSrc: "",
	});

	const [error, setError] = useState({
		flag: false,
	});

	const [selectCarga, setSelectCarga] = useState({
		flag: false,
	});

	const [planejarCarga, setPlanejarCarga] = useState({
		flag: false,
		cargas_id: "",
	});

	const [cancelarTransferencia, setCancelarTransferencia] = useState({
		flag: false,
	});

	const onClickEditarTransferencia = (e) => {
		if (bodyList.status < 3)
			return setError({
				flag: true,
				list: `Planejamento de Carga indisponível antes da separação`,
				title: "Aviso",
			});
		if (!bodyList.cargas_id) {
			setSelectCarga({ flag: true, transferencia: bodyList.transferencia });
			return;
		}
		setPlanejarCarga({
			flag: true,
			cargas_id: bodyList.cargas_id,
			transferencia: bodyList.transferencia,
		});
	};

	const [addPedidos, setAddPedidos] = useState({
		flag: false,
		cargas_id: "",
		id_filial: ``,
	});

	const onClickAdicionarPedido = (e) => {
		if (bodyList.status != 5)
			return setError({
				flag: true,
				list: `Inserção de pedido não autorizado até a finalização do planejamento do separador!`,
				title: "Aviso",
			});
		setAddPedidos((last) => ({
			...last,
			flag: true,
			cargas_id: bodyList.cargas_id,
			id_filial: bodyList.id_filial,
			transferencia: bodyList.transferencia,
		}));
	};

	const onClickCancelarPedido = (e) => {
		setCancelarTransferencia({
			flag: true,
			title: "Cancelar Pedido de Transferencia N°" + bodyList.id,
			body: bodyList,
		});
	};

	return (
		<Fragment>
			{
				<tr ref={refRow} onClick={onClickTableRow}>
					{headList.map((item, _index) => {
						if (item === "index") {
							return <td key={_index}> {index + 1}</td>;
						} else if (item === "admin") {
							return (
								<td key={_index}>
									<div className="admin" style={{ alignItems: "baseline" }}>
										{bodyList.status == 0 &&
										(user.id_filial == "2" ||
											user.tipo.toLowerCase() == "empresa") ? (
											<span className="verificar">
												<i
													className="bi bi-x-lg"
													title="Excluir Pedido"
													onClick={onClickCancelarPedido}
													ref={refEditar}
												></i>
											</span>
										) : (
											""
										)}

										<span className="verificar">
											<i
												className="bi bi-plus-circle"
												title="Verificar Produtos"
												onClick={onClickPlusRow}
												ref={refEditar}
											></i>
										</span>
										{(user.id_filial == "2" ||
											user.tipo.toLowerCase() == "empresa") &&
										bodyList.status != 6 ? (
											<span className="verificar">
												<i
													className="bi bi-pencil"
													title="Adicionar Pedido"
													onClick={onClickAdicionarPedido}
												></i>
											</span>
										) : (
											""
										)}
										{user.tipo.toLowerCase() == "separador" ? (
											<span className="verificar">
												<i
													className="bi bi-truck"
													title="Editar Transferencia"
													onClick={onClickEditarTransferencia}
												></i>
											</span>
										) : (
											""
										)}
									</div>
								</td>
							);
						} else if (item === "id_filial") {
							/* primary key can not be modified */
							return (
								<td key={_index}>
									<div style={{
										color: getColorOfFilial(bodyList.id_filial).color,
										backgroundColor: getColorOfFilial(bodyList.id_filial).bgColor,
										padding: '4px 6px',
										borderRadius: '4px',
										textAlign: 'center',
										boxShadow: '0 0 5px #0A061E80',
										border: '1px solid #E2E3DF80',
										width:'max-content'

									}}>
										{
											getFilialName(filiaisList, bodyList.id_filial)
										}
									</div>
								</td>
							);
						} else if (item === "id_filial_destino") {
							/* primary key can not be modified */
							return (
								<td key={_index}>
									<div style={{
										color: getColorOfFilial(bodyList.id_filial_destino).color,
										backgroundColor: getColorOfFilial(bodyList.id_filial_destino).bgColor,
										padding: '4px 6px',
										borderRadius: '4px',
										textAlign: 'center',
										boxShadow: '0 0 5px #0A061E80',
										border: '1px solid #E2E3DF80',
										width:'max-content'

									}}>
										{
											getFilialName(filiaisList, bodyList.id_filial_destino)
										}
									</div>
								</td>
								// <td key={_index}>
								// 	{filiaisList.filter(
								// 		(item) => item.idFilial == bodyList.
								// 	)[0]?.identificacaoInterna ?? "NÃO INFORMADO"}
								// </td>
							);
						} else if (item === "danfe") {
							return (
								<td key={_index}>
									<div className="admin">
										<span className="excluir">
											{!bodyList[item] ? (
												"Sem Arquvio"
											) : (
												<i
													className="bi bi-file-earmark-pdf"
													onClick={(e) => {
														setVerPDF({
															show: true,
															PDF:
																locationDev +
																"/files/financeiro/danfes/" +
																bodyList[item],
															title: "Danfe Assinada",
														});
													}}
													style={{ cursor: "pointer" }}
												>
													Assinado
												</i>
											)}
										</span>
									</div>
								</td>
							);
						} else if (item === "assinar") {
							return (
								<td key={_index}>
									<div className="admin">
										<span className="excluir">
											<i className="bi bi-pencil-square" title="Assinar"></i>
										</span>
									</div>
								</td>
							);
						} else if (item === "romaneio") {
							return (
								<td key={_index} className={item}>
									{" "}
									{String(bodyList[item]).padStart(7, "0")}
								</td>
							);
						} else if (item === "emissao") {
							return (
								<td key={_index} className="rowDataWidth">
									{" "}
									{correctDataUTC(bodyList.emissao)}
								</td>
							);
						} else if (item === "updated_at") {
							return (
								<td key={_index} className="rowDataWidth">
									{" "}
									{correctDataUTC(bodyList.updatedAt)}
								</td>
							);
						} else if (item === "created_at") {
							return (
								<td key={_index} className="rowDataWidth">
									{" "}
									{correctDataUTC(bodyList.createdAt)}
								</td>
							);
						} else if (item === "valor_total") {
							return (
								<td key={_index} className="rowDataWidth">
									{" "}
									{valorMoeda(bodyList.valor_total)}
								</td>
							);
						} else if (item === "status") {
							return (
								<td key={_index} className="rowDataWidth">
									{" "}
									{getStatusPedidosTransferencia(bodyList.status)}
								</td>
							);
						} else if (
							item === "nome_razao" ||
							item === "endereco" ||
							item === "cpf_cnpj" ||
							item === "cep"
						) {
							/* primary key can not be modified */
							return (
								<td key={_index} className="rowDataWidth">
									{bodyList[item]}
								</td>
							);
						} else if (item === "foto_pedido_entregue") {
							/* primary key can not be modified */

							return (
								<td key={_index} style={{ minWidth: "100px" }}>
									{bodyList[item] ? (
										<Card.Img
											variant="top"
											src={`${locationDev}/files/users/entregas/carga_${bodyList.cargas_id}/${bodyList[item]}`}
											style={{ cursor: "pointer" }}
											onClick={(e) =>
												setFotoProdutoEntregue({
													flag: true,
													imgSrc: `${locationDev}/files/users/entregas/carga_${bodyList.cargas_id}/${bodyList[item]}`,
													alternative: "Produto Entregue",
													title: `Romaneio N°${String(
														bodyList.romaneio
													).padStart(7, 0)}, Cliente: ${
														bodyList.nome_razao
													}, Pedido N°${bodyList.numero_pedido}`,
												})
											}
										/>
									) : (
										<span className="excluir">Sem Arquivo</span>
									)}
								</td>
							);
						} else if (item === "qr_code_id") {
							return (
								<td key={_index} className="rowDataWidth">
									<QRCodeSVG value={"{\n" + bodyList.qr_code_id + "}"} />
								</td>
							);
						} else if (item.toLowerCase() === "peso_total") {
							return (
								<td key={_index} className={item}>
									{" "}
									{Number(bodyList.peso_total).toLocaleString()}
								</td>
							);
						}
						return (
							<td key={_index} className={item}>
								{" "}
								{bodyList[item]}
							</td>
						);
					})}
				</tr>
			}
			{produtosModal.flag ? (
				<ModalListarProdutosTransferencias
					msg={""}
					title={"Produtos do Pedido"}
					show={produtosModal}
					setShow={setProdutosModal}
					pedido={produtosModal.pedido}
				/>
			) : (
				""
			)}
			{error.flag ? (
				<ModalError
					title={error.title}
					msg={error.msg}
					setShow={setError}
					show={error.flag}
					{...error}
				/>
			) : (
				""
			)}
			{addPedidos.flag ? (
				<ModalAddPedidosTransferencia
					setShow={setAddPedidos}
					show={addPedidos.flag}
					title={"Adicionar Pedido de Transferencia a carga"}
					cargas_id={addPedidos.cargas_id}
					id_filial={addPedidos.id_filial}
					transferencia={addPedidos.transferencia}
				/>
			) : (
				""
			)}
			{cancelarTransferencia.flag ? (
				<ModalCancelarPedido
					setShow={setCancelarTransferencia}
					show={cancelarTransferencia.flag}
					body={cancelarTransferencia.body}
					title={cancelarTransferencia.title}
				/>
			) : (
				""
			)}
		</Fragment>
	);
}

function ModalCancelarPedido(props) {
	const user = useSelector((state) => state.userSlice);
	const dispatch = useDispatch();

	const [error, setError] = useState({
		flag: false,
	});

	const handlerSimpleClose = async () => {
		props.setShow((last) => ({ ...last, flag: false }));
	};

	const [loading, setLoading] = useState({
		isLoading: false,
		msg: "Excluir",
		style: "primary",
	});

	const updateTransferenciasArray = () => {
		const body =
			user.tipo != "empresa" && user.id_filial != 2
				? { id_filial: user.id_filial }
				: {};

		getTransferencias(body)
			.then((response) => {
				dispatch(
					updateTransferencias(
						response
							.filter((item) => item.status < 6)
					)
				);
				setLoading({
					isLoading: false,
					msg: "Cancelado",
					style: "success",
				});
				setTimeout(() => {
					handlerSimpleClose();
				}, 500);
			})
			.catch((error) => {
				setError({
					show: true,
					message: getErrorMessage(error),
				});
			});
	};
	const submitDataExclude = (e) => {
		setLoading({
			isLoading: true,
			msg: "Cancelando",
			style: "danger",
		});
		const url =
			user.tipo != "empresa" ? "gestao/transferencias" : "transferencias";

		updateTable(url, props.body.id, {
			data_cancelamento: new Date().toISOString(),
		})
			.then((resp) => {
				updateTransferenciasArray();
			})
			.catch((err) => {
				setError({
					show: true,
					message: getErrorMessage(err),
				});
			});
	};
	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="lg"
			>
				<Modal.Header closeButton>
					<Modal.Title>{props.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						<Form>
							{Object.keys(props.body).map((key, index) => (
								<Row className="align-items-end" key={index}>
									<Col sm="3" className="mb-2">
										<Form.Label>{key.toUpperCase()}:</Form.Label>
									</Col>
									<Col className="mb-2">
										<Form.Control
											className="form-control-input"
											defaultValue={props.body[key]}
											disabled
										/>
									</Col>
								</Row>
							))}
						</Form>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						<Col>
							<Button variant={loading.style} onClick={submitDataExclude}>
								{loading.isLoading ? (
									<Loading msg={loading.msg} />
								) : (
									loading.msg
								)}
								{loading.style === "success" ? (
									<i className="bi bi-check"></i>
								) : (
									""
								)}
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
			{error.show ? <ModalErrorPretty setShow={setError} {...error} /> : ""}
		</>
	);
}
