import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
	createManifesto,
	createManifestoV2,
	getAllData,
	getData,
	numerosEditadoBR,
	valorMoeda,
	verificaTipoUsuario,
} from "../../services/Servicos";
import Loading from "../loading/Loading";

import "./Modal.scss";
import ModalError from "./ModalError";
import axios from "axios";
import ModalErrorPretty from "./ModalErrorPretty";
import { getErrorMessage } from "../../services/helpers";
import ModalSuccessPretty from "./ModalSuccessPretty";

export default function ModalMontaManifestoV2(props) {
	const caminhao = useSelector(
		(state) => state.userSlice.caminhoesList.data
	).find((item) => item.status && props.body.caminhao === item.placa);
	const user = useSelector((state) => state.userSlice);
	const [firstLoading, setFirstLoading] = useState(true);
	const [loading, setLoading] = useState({
		isLoading: false,
		msg: "Salvar",
		style: "primary",
	});
	const [municipios, setMunicipios] = useState([]);
	const [estados, setEstados] = useState([]);
	const [estadosDestino, setEstadosDestino] = useState([]);
	const [municipiosOrigem, setMunicipiosOrigem] = useState([]);
	const [municipiosDestino, setMunicipiosDestino] = useState([]);

	const [success, setSuccess] = useState({show: false, message:""});
	const [pedidos, setPedidos] = useState([]);
	const [body, setBody] = useState({
		ambiente: 1,
		emitente: 2,
		// transportador: 2,
		modalidade: 1, // rodoviario
		uf_carregamento: "MT",
		uf_descarregamento: "MT",
		valor_carga: "500.00",
		unidade: "01",
		peso_bruto: "50.0000",
		carregamento: [],
		descarregamento: [],
		percurso: [],
		rodoviario: {
			rntrc: "03139653",
			veiculo_tracao: {
				placa: caminhao.placa,
				tara: caminhao.tara * 1000,
				uf_licenciamento: "MT",
				tipo_rodado: '',
				tipo_carroceria: '',
				capacidade_kg:  caminhao.capacidade * 1000,
				// proprietario: {
				// 	cnpj: "00.000.000/0000-00",
				// 	razao_social: "Proprietário 1",
				// 	ie: "00000",
				// 	uf: "GO",
				// 	tipo_proprietario: "2",
				// },
			},
			condutor: [
				{
					cpf: props.body.caminhoneiro,
					nome: "Condutor 1",
				},
			],
			// ciot: [
			// 	{
			// 		codigo_ciot: "000000000000",
			// 		cpf_responsavel: "000.000.000-00",
			// 	},
			// ],
		},
		// seguro: [],
		// produto_predominante: {},
	});
	const [addNFManual, setAddNFManual] = useState({
		uf:'MT',
		codigo_municipio: "",
		nome_municipio: '',
		chave: ''
	})
	const [error, setError] = useState(false);
	const formRef = useRef();

	const handlerSimpleClose = () =>
		props.setShow((last) => ({ ...last, flag: false }));

	const submitDataEdit = async () => {
		if (!(body.carregamento[0])?.codigo_municipio) return setError({	show: true, message: "Local de Carregamento não especificado!" });
		if (!body.rodoviario?.veiculo_tracao?.placa) return setError({	show: true, message: "Caminhão não especificado!" });
		if (!(body.rodoviario.condutor[0])?.cpf) return setError({	show: true, message: "Condutor não especificado!" });
		if (pedidos.find((item) => !item.codigo_ibge)) return setError({	show: true, message: "Pedido da Carga sem municipio de descarregamento" });
		if (pedidos.find((item) => !item.peso_nf)) {
			return setError({
				show: true, message: "Pedido da Carga sem PESO DA NOTA FISCAL"
				, children: <div>
					{pedidos
						.filter((item) => !item.peso_nf)
						.map((item) => <div>{item.numero_pedido}</div>)
					}
				</div>
			});
		}
		if (!body.rodoviario?.veiculo_tracao?.tipo_rodado) return setError({	show: true, message: "Tipo do caminhão não informado!" });
		if (!body.rodoviario?.veiculo_tracao?.tipo_carroceria) return setError({	show: true, message: "Tipo de Carroceria não informado!" });

		setLoading({
			isLoading: true,
			msg: "Enviando",
			style: "warning",
		});

		try {
			const sendData = await createManifestoV2({
				manifesto: body,
				carga: {
					caminhao: props.body.caminhao,
					caminhoneiro: props.body.caminhoneiro,
					cargas_id: props.body.id,
					emissao: new Date().toISOString(),
					filial: props.body.id_filial,
				}
			})
			setLoading({isLoading: false, msg:"Manifesto Gerado",style:'success'})
			props.updateStatusManifesto()
			setTimeout(() => {
				handlerSimpleClose()
			}, 1000)
		} catch (error) {
			setError({ show: true, message: getErrorMessage(error) })
		} finally {
			setLoading({isLoading: false, msg:"Enviar",style:'primary'})
		}
		// createManifesto(
		// 	{
		// 		manifesto: bodySent,
		// 		carga: {
		// 			caminhao: props.body.caminhao,
		// 			caminhoneiro: props.body.caminhoneiro,
		// 			cargas_id: props.body.id,
		// 			emissao: new Date().toISOString(),
		// 			filial: props.body.id_filial,
		// 		},
		// 	},
		// 	user.tipo
		// )
		// 	.then((response) => {
		// 		setLoading({
		// 			isLoading: false,
		// 			msg: "Enviado",
		// 			style: "success",
		// 		});
		// 		props.setCodigoMDFE(response.data);
		// 		setTimeout(() => {
		// 			handlerSimpleClose();
		// 		}, 1000);
		// 	})
		// 	.catch((error) => {
		// 		setLoading({
		// 			isLoading: false,
		// 			msg: "Error",
		// 			style: "danger",
		// 		});

		// 		setError({
		// 			title: "Erro",
		// 			flag: true,
		// 			list: error.response.data ?? "Erro no banco de dados",
		// 		});

		// 		setTimeout(() => {
		// 			setLoading({
		// 				isLoading: false,
		// 				msg: "Enviar",
		// 				style: "primary",
		// 			});
		// 		}, 1000);
		// 	});
	};

	const onChangeInput = (e) => {
		let extraBody = {}
		if (e.target.name == 'carregamento') {
			const muni = municipiosOrigem.find(i => i.nome == e.target.value)

			setBody((last) => ({
				...last,
				carregamento: [
					{
						codigo_municipio: "" + muni.id,
						nome_municipio: muni.nome,
					},
				]
			}));
			return
		}
		if (e.target.name == 'uf_caminhao') {
			setBody((last) => ({
				...last,
				rodoviario: {
					...last.rodoviario,
					veiculo_tracao: {
						...last.rodoviario.veiculo_tracao,
						uf_licenciamento: e.target.value
					}
				}
			}));
			return
		}
		if (e.target.name == 'tipo_carroceria') {
			setBody((last) => ({
				...last,
				rodoviario: {
					...last.rodoviario,
					veiculo_tracao: {
						...last.rodoviario.veiculo_tracao,
						tipo_carroceria: e.target.value
					}
				}
			}));
			return
		}
		if (e.target.name == 'tipo_rodado') {
			setBody((last) => ({
				...last,
				rodoviario: {
					...last.rodoviario,
					veiculo_tracao: {
						...last.rodoviario.veiculo_tracao,
						tipo_rodado: e.target.value
					}
				}
			}));
			return
		}

		setBody((last) => ({
			...last,
			[e.target.name]: e.target.value,
		}));
	};


	useEffect(() => {
		axios
			.get(
				`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${body.uf_carregamento}/municipios`
			)
			.then(async (response) => {
				setMunicipiosOrigem(response.data);
				setBody(last => ({...last, carregamento:[]}))
			});
	}, [body.uf_carregamento]);

	useEffect(() => {
		axios
			.get(
				`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${body.uf_descarregamento}/municipios`
			)
			.then(async (response) => {
				setMunicipiosDestino(response.data);
			});
	}, [body.uf_descarregamento]);
	

	useEffect(() => {
		axios
			.get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
			.then((response) => {
				setEstados(
					response.data.sort((a, b) => {
						if (a.sigla > b.sigla) return 1;
						if (a.sigla < b.sigla) return -1;
						return 0;
					})
				);
				setEstadosDestino(
					response.data.sort((a, b) => {
						if (a.sigla > b.sigla) return 1;
						if (a.sigla < b.sigla) return -1;
						return 0;
					})
				);
			});
		axios
			.get(
				"https://servicodados.ibge.gov.br/api/v1/localidades/estados/MT/municipios"
			)
			.then(async (response) => {
				setMunicipios(response.data);
				const municipiosF = response.data;

				// primeiro as informações de identificação de carga -> identificacao
				const cidadeOrigem = user.rotasList.data.find(
					(rota) => rota.rota === props.body.rota && rota.step == 1
				);
				const municipio = response.data.find(
					(municipio) => municipio.nome == cidadeOrigem?.cidade
				);

				const [dia, mes, ano] = props.body.data_saida.split("/");
				// Informações de viagem -> modal
				// Informações de viagem -> docs
				// Informações de viagem -> seguro
				// Informações de viagem -> totalizadores
				const romaneiosGet = await getData(
					verificaTipoUsuario(user) + "cargas/pedidos/carga",
					props.body.id
				);
				const romaneios = romaneiosGet.data
				// Informações de viagem -> lacres
				// Informações de viagem -> autorizados
				// Informações de viagem -> informações adicionais

				setPedidos(romaneiosGet.data);

				const municipiosDscarga = romaneiosGet.data.map((item) => {
					const muni = municipiosF.find(
						(municipio) =>
							String(municipio.nome)
								.normalize("NFD")
								.replace(/[\u0300-\u036f]/g, "")
								.toLowerCase() ==
							String(item.cidade)
								.normalize("NFD")
								.replace(/[\u0300-\u036f]/g, "")
								.toLowerCase()
					);
					return {
						...item,
						municipio: muni?.nome ?? null,
						codigo_ibge: muni?.id ?? null,
						chave: item.chave_nfe,
					};
				});

				setPedidos(municipiosDscarga);

				const municipiosDiferentes = municipiosDscarga
					.filter(
						(i, n, s) => s.findIndex((o) => o.municipio === i.municipio) == n
					)
					.filter((item) => item.municipio);

				const final = municipiosDiferentes.map((item) => ({
					codigo_municipio: "" + item.codigo_ibge,
					nome_municipio: item.municipio,
					documentos_fiscais: [
						...municipiosDscarga
							.filter((item2) => item.municipio === item2.municipio)
							.map((item2) => ({
								chave: item2.chave,
							})),
					],
				}));
				// setMunicipiosDescar([]);

				// console.log(romaneios)
				let motorista
				try {
					const motoristas = await getAllData('funcionarios/motoristas')
					motorista = motoristas.data.find(moto => moto.cpf === props.body.caminhoneiro)
				} catch (error) {
					setError({show: true, message: 'Não foi possivel consultar informações do motorista! Contate o suporte'})
				}

				setBody(last =>({
					...last, 
					peso_bruto:  "" + romaneios.reduce((p, c) => p + c.peso_nf, 0),
					valor_carga: "" + romaneios.reduce((p, c) => p + c.valor_total, 0),
					carregamento: [{
							"nome_municipio": municipio?.nome,
							"codigo_municipio": "" + municipio?.id
					}],
					descarregamento: final,
					rodoviario: {
						...last.rodoviario,
						condutor: [
							{
								cpf: motorista?.cpf,
								nome: motorista?.name
							}
						]
					}
				}))

				

				setFirstLoading(false);
			});
	}, []);
	
	const onChangeDescarregamentoSection = (e) => {
		let extraBody = {}
		if (e.target.name == 'municipio') {
			const muni = municipiosDestino.find(i => i.id == e.target.value)
			extraBody = {
				codigo_municipio: muni.id,
				nome_municipio: muni.nome,
			}
			
		}
		setAddNFManual((last) => ({
			...last,
			[e.target.name]: e.target.value,
			...extraBody
		}));
	};

	const handlerAddPedidoMunicipio = (e) => {
		
		
		if (!addNFManual.chave ) return setError({ show: true, message: 'Pedido não selecionado',});
		if (!addNFManual.nome_municipio ) return setError({ show: true, message: 'Municipio não selecionado.',});

		
		let descarregamento = [...body.descarregamento]

		if (descarregamento.find(i => i.codigo_municipio == addNFManual.codigo_municipio)) {
			descarregamento.map(i => {
				let item = {...i}
				if (item.codigo_municipio == addNFManual.codigo_municipio) {
					item.documentos_fiscais.push({chave: addNFManual.chave})
				}
				return item
			})
		} else {
			descarregamento = [
			...descarregamento,
				{
					codigo_municipio: addNFManual.codigo_municipio,
					nome_municipio: addNFManual.nome_municipio,
					documentos_fiscais: [{chave: addNFManual.chave}]
				}
			]
		}
		
		const pedi = [...pedidos]
		setPedidos(
			pedi.map(pedid => ({ ...pedid, codigo_ibge: pedid.chave_nfe == addNFManual.chave ? addNFManual.codigo_municipio : pedid.codigo_ibge }))
		)
		setBody(last => ({ ...last, descarregamento }))
		setAddNFManual({})
	};
	const handleRemoveChaveNF = (chave) => {
		let ped = [...pedidos]
		let descarregamento = [...body.descarregamento]
		ped = ped.map(i => {
			let item = { ...i }
			if (i.chave === chave) {
				item.municipio = null
				item.codigo_ibge = null
			}
			return item
		})
		descarregamento = descarregamento.map(desc => {
			let item = {...desc}
			if (item.documentos_fiscais.find(i => i.chave == chave)) {
				item.documentos_fiscais = item.documentos_fiscais.filter(i => i.chave !== chave)
			}
			return item
		})
		.filter(item => item.documentos_fiscais.length)
		
		setBody(last => ({ ...last, descarregamento }))
		
		setPedidos(ped)
	}

	const [section, setSection] = useState({
		informacoes_gerais: true,
		carregamento: false,
		descarregamento: false,
		veiculo: false,
		condutor: false,
	})


	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						Gerar Manifesto - Carga N° {props.body.id} {firstLoading ? <Spinner animation="border" size="sm" /> : ""}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form ref={formRef}>
						<div className="card-header d-flex justify-content-between" style={{ cursor: 'pointer' }} onClick={() => setSection(last => ({ ...last, informacoes_gerais: !last.informacoes_gerais }))}>
							<h4 className="m-0">Informações Gerais</h4>
							<i className={`bi bi-caret-up-fill`}
								style={{ cursor: 'pointer', transition: '0.5s', rotate: section.informacoes_gerais ? '180deg' : "0deg" }}
								></i>
						</div>
						<div hidden={ !section.informacoes_gerais }>
							<Row className="mb-2 align-items-center">
								<Col sm="6">
									<Form.Label>UF Carregamento:</Form.Label>
									<Form.Select
										type="text"
										name="uf_carregamento"
										onChange={onChangeInput}
										value={body.uf_carregamento}
									>
										<option value=""></option>
										{estados.map((item, key) => (
											<option value={item.sigla} key={key}>
												{item.sigla}
											</option>
										))}
									</Form.Select>
								</Col>
								<Col sm="6">
									<Form.Label>UF Descarregamento:</Form.Label>
									<Form.Select
										type="text"
										name="uf_descarregamento"
										onChange={onChangeInput}
										value={body.uf_descarregamento}
									>
										<option value=""></option>
										{estados.map((item, key) => (
											<option value={item.sigla} key={key}>
												{item.sigla}
											</option>
										))}
									</Form.Select>
								</Col>
								<Col>
								</Col>
							</Row>
							<Row className="mb-2 align-items-center">
								<Col sm="6">
									<Form.Label>Valor da Carga:</Form.Label>
									<Form.Control
										type="text"
										disabled
										value={valorMoeda(body.valor_carga)}
									/>
								</Col>
								<Col sm="6">
									<Form.Label>Peso da Carga <b>[kg]</b>:</Form.Label>
									<Form.Control
										type="text"
										disabled
										value={numerosEditadoBR(body.peso_bruto)}
									/>
								</Col>
							</Row>
						</ div>

						<div className="card-header d-flex justify-content-between" style={{ cursor: 'pointer' }}onClick={() => setSection(last => ({ ...last, carregamento: !last.carregamento }))}>
							<h4 className="m-0">Carregamento</h4>
							<i className={`bi bi-caret-up-fill`}
								style={{ cursor: 'pointer', transition: '0.5s', rotate: section.carregamento ? '180deg' : "0deg" }}
								></i>
						</div>
						<div hidden={!section.carregamento}>
							<Row className="mb-2 align-items-center">
								<Col sm="6">
									<Form.Label>Município:</Form.Label>
									<Form.Select
										type="text"
										name="carregamento"
										onChange={onChangeInput}
										value={body.carregamento.length ? (body.carregamento[0])?.nome_municipio : ''}
									>
										<option value=""></option>
										{municipiosOrigem.map((item, key) => (
											<option value={item.nome} key={key}>
												{item.nome}
											</option>
										))}
									</Form.Select>
								</Col>
							</Row>
						
						</div>

						<div className="card-header d-flex justify-content-between" style={{ cursor: 'pointer' }} onClick={() => setSection(last => ({ ...last, descarregamento: !last.descarregamento }))}>
							<h4 className="m-0">Descarregamento</h4>
							<i className={`bi bi-caret-up-fill`}
								style={{ cursor: 'pointer', transition: '0.5s', rotate: section.descarregamento ? '180deg' : "0deg" }}
								></i>
						</div>
						<div hidden={!section.descarregamento}>
							<Row className="mb-2">
								<Col sm="12" className="mb-2">
									<Row className="mb-2">
										<Col>
										</Col>
									</Row>
									<Row className="align-items-baseline">
										<Col sm="6">
											<Form.Label>Município:</Form.Label>
											
											<Form.Select
												type="text"
												name="municipio"
												onChange={onChangeDescarregamentoSection}
												value={addNFManual?.codigo_municipio || ''}
											>
												<option value="" ></option>
												{municipiosDestino.map((item, key) => (
													<option value={item.id} key={key}>
														{item.nome}
													</option>
												))}
											</Form.Select>
										</Col>
										<Col sm="6">
											<Form.Label>Pedido:</Form.Label>
											<Form.Select name="chave" onChange={onChangeDescarregamentoSection} value={addNFManual?.chave  || ''}>
												<option></option>
												{pedidos
													.filter((item) => !item.codigo_ibge)
													.map((i, k) => (
														<option
															key={k}
															disabled={i?.codigo_ibge ? true : false}
															value={i.chave_nfe}
														>
															{i.numero_pedido} - {i.nome_razao}: {i.cidade}
														</option>
													))}
											</Form.Select>
										</Col>
										<Col sm="6">
											<Form.Label style={{ color: "transparent" }}>
												Only
											</Form.Label>
											<Button
												variant="primary"
												onClick={handlerAddPedidoMunicipio}
											>
												+Add
											</Button>
										</Col>
									</Row>
									{/* <Row className="align-items-baseline">
										<Col sm="4">
											<Form.Label>Percurso:</Form.Label>
											<Form.Select name="trajeto">
												{estadosDestino.map((item, key) => (
													<option value={item.sigla} key={key}>
														{item.sigla}
													</option>
												))}
											</Form.Select>
										</Col>
										<Col sm="4">
											<Form.Label style={{ color: "transparent" }}>
												Only
											</Form.Label>
											<Button
												variant="primary"
												// onClick={handlerAdicionarPercurso}
											>
												+Add
											</Button>
										</Col>
										<Col sm="12">
											{body.percurso.map((key, index) => {
												return (
													<span className="filters-card" key={index}>
														<span className="filters">
															<span className="title">{key}</span>
															<i
																className="bi bi-x-lg"
																// onClick={onClickRemoveFilter}
															></i>
														</span>
													</span>
												);
											})}
										</Col>
									</Row> */}
									<Row className="mt-2">
										{body.descarregamento.map((item, key) => {
											return (
												<div className="" key={key}>
													<h6 className="card-header  mb-1 my-4">
														{item.nome_municipio}
													</h6>
													{item.documentos_fiscais.map((item2, key2) => (
														<div key={key2} style={{ backgroundColor: '#161409', color: 'white', padding: '2px 5px', width: 'fit-content' }} className="mb-2">
															{item2.chave} { "  " }
															<i className="bi bi-x border" style={{ backgroundColor: 'white', color: 'black', fontSize:'0.9rem', cursor: 'pointer' }} onClick={() => handleRemoveChaveNF(item2.chave)}></i>
														</div>
													))}
												</div>
											);
										})}
									</Row>
								</Col>
							</Row>
						</div>
						<div className="card-header d-flex justify-content-between" style={{ cursor: 'pointer' }} onClick={() => setSection(last => ({ ...last, veiculo: !last.veiculo }))}>
							<h4 className="m-0">Veículo</h4>
							<i className={`bi bi-caret-up-fill`}
								style={{ cursor: 'pointer', transition: '0.5s', rotate: section.veiculo ? '180deg' : "0deg" }}
								></i>
						</div>
						<div hidden={!section.veiculo}>
							<Row className="mb-2 align-items-center">
								<Col sm="6">
									<Form.Label>RNTC:</Form.Label>
									<Form.Control
										type="text"
										disabled
										value={body.rodoviario?.rntrc}
									/>
								</Col>
								<Col sm="6">
									<Form.Label>Placa:</Form.Label>
									<Form.Control
										type="text"
										disabled
										value={body.rodoviario?.veiculo_tracao?.placa}
									/>
								</Col>
							</Row>
							<Row className="mb-2 align-items-center">
								<Col sm="6">
									<Form.Label>Tara:</Form.Label>
									<Form.Control
										type="text"
										disabled
										value={body.rodoviario?.veiculo_tracao?.tara}
										/>
								</Col>
								<Col sm="6">
									<Form.Label>Capacidade:</Form.Label>
									<Form.Control
										type="text"
										disabled
										value={body.rodoviario?.veiculo_tracao?.capacidade_kg}
									/>
								</Col>
							</Row>
							<Row className="mb-2 align-items-center">
								<Col sm="6">
									<Form.Label>UF Licenciamento:</Form.Label>
									<Form.Select
										type="text"
										name="uf_caminhao"
										onChange={onChangeInput}
										value={body?.rodoviario?.veiculo_tracao?.uf_licenciamento}
									>
										<option value=""></option>
										{estados.map((item, key) => (
											<option value={item.sigla} key={key}>
												{item.sigla}
											</option>
										))}
									</Form.Select>
								</Col>
								<Col sm="6">
									<Form.Label>Capacidade:</Form.Label>
									<Form.Control
										type="text"
										disabled
										value={body.rodoviario?.veiculo_tracao?.capacidade_kg}
									/>
								</Col>
							</Row>
							<Row className="mb-2 align-items-center">
								<Col sm="6">
									<Form.Label>Tipo de Caminhao:</Form.Label>
									<Form.Select
										type="text"
										name="tipo_rodado"
										onChange={onChangeInput}
										value={body?.rodoviario?.veiculo_tracao?.tipo_rodado}
									>
									<option value="">Selecione</option>
									<option value="01">Truck</option>
									<option value="02">Toco</option>
									<option value="03">Cavalo Mecânico</option>
									<option value="04">VAN</option>
									<option value="05">Sider</option>
									</Form.Select>
								</Col>
								<Col sm="6">
									<Form.Label>Tipo de Carroceria:</Form.Label>
									<Form.Select
										type="text"
										name="tipo_carroceria"
										onChange={onChangeInput}
										value={body?.rodoviario?.veiculo_tracao?.tipo_carroceria}
									>
										<option value="">Selecione</option>
										<option value="00">não aplicável</option>
										<option value="01">Aberta</option>
										<option value="02">Fechada/Baú</option>
										<option value="03">Granelera</option>
										<option value="04">Porta Container</option>
										<option value="05">Sider</option>
									</Form.Select>
								</Col>
							</Row>
						</div>
						
						<div className="card-header d-flex justify-content-between" style={{ cursor: 'pointer' }} onClick={() => setSection(last => ({ ...last, condutor: !last.condutor }))}>
							<h4 className="m-0">Condutor</h4>
							<i className={`bi bi-caret-up-fill`}
								style={{ cursor: 'pointer', transition: '0.5s', rotate: section.condutor ? '180deg' : "0deg" }}
								></i>
						</div>
						
						<div hidden={!section.condutor}>
							<Row className="mb-2 align-items-center">
								<Col sm="6">
									<Form.Label>Nome:</Form.Label>
									<Form.Control
										type="text"
										disabled
										value={(body.rodoviario?.condutor[0])?.nome || 'N/A'}
									/>
								</Col>
								<Col sm="6">
									<Form.Label>CPF:</Form.Label>
									<Form.Control
										type="text"
										disabled
										value={(body.rodoviario?.condutor[0])?.cpf || 'N/A'}
									/>
								</Col>
							</Row>
						</div>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						<Col>
							<Button variant={loading.style} onClick={submitDataEdit}>
								{loading.isLoading ? (
									<Loading msg={loading.msg} />
								) : (
									loading.msg
								)}
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
				{error.show ? (
					<ModalErrorPretty setShow={setError} {...error} />
				) : (
					""
				)}
				{success.show ? (
					<ModalSuccessPretty setShow={setSuccess} {...success} />
				) : (
					""
				)}
			</Modal>
		</>
	);
}
